import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { AsInput } from "app/subframe";
import * as React from "react";

export interface CredsConfirmModalProps {
  isOpen: boolean;
  onOpenChange: (show: boolean) => void;
  onSubmit: (data: any) => void;
}

export const CredsConfirmModal: React.FC<CredsConfirmModalProps> = ({
  isOpen,
  onOpenChange,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="lg"
      className="rounded"
      disableAnimation
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col items-center gap-10">
              Please confirm your password
            </ModalHeader>
            <form className="flex flex-col" onSubmit={onSubmit}>
              <ModalBody className="py-4">
                <AsInput
                  name="password"
                  placeholder="Enter your password"
                  label="Password"
                  textPosition="left"
                  required
                  type="password"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  className="w-[200px] rounded-sm self-end font-semibold border border-zinc-400"
                  variant="ghost"
                  type="submit"
                >
                  Confirm
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
