import Avvvatars from "avvvatars-react";
import * as React from "react";
import { Avatar as SFAvatar } from "app/subframe/components/Avatar";
import classNames from "classnames";

export interface AvatarProps {
  src: string;
  name: string;
  className?: string;
  size?: number;
  type?: "shape" | "character";
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  size = 40,
  type = "shape",
}) => {
  return src ? (
    <img
      src={src}
      alt={name}
      className={classNames("rounded flex-none")}
      style={{
        width: size,
        height: size,
      }}
    />
  ) : (
    <div>
      <Avvvatars size={size} radius={4} value={name} style={type} />
    </div>
  );
};
