"use client";
/*
 * Documentation:
 * Schedule Date chip — https://app.subframe.com/feb895689ba0/library?component=Schedule+Date+chip_9985747c-6a2c-4f4c-a979-9397e4043b0b
 */

// @subframe/sync-disable

import React from "react";
import * as SubframeCore from "@subframe/core";
import classNames from "classnames";

interface ScheduleDateChipRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  text2?: string;
  isSelected?: boolean;
  className?: string;
}

const ScheduleDateChipRoot = React.forwardRef<
  HTMLElement,
  ScheduleDateChipRootProps
>(function ScheduleDateChipRoot(
  {
    text,
    text2,
    className,
    isSelected,
    ...otherProps
  }: ScheduleDateChipRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/9985747c flex flex-col items-center gap-0.5",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      {text ? (
        <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
          {text}
        </span>
      ) : null}
      <div
        className={classNames(
          "flex h-8 w-8 flex-none flex-col items-center justify-center gap-4 rounded-[4px] group-focus-within/9985747c:bg-brand-500",
          {
            "bg-brand-500": isSelected,
          }
        )}
      >
        {text2 ? (
          <span className="font-['Inter'] text-[18px] font-[500] leading-[24px] text-neutral-900 text-center">
            {text2}
          </span>
        ) : null}
      </div>
    </div>
  );
});

export const ScheduleDateChip = ScheduleDateChipRoot;
