import React from "react";
import { format } from "date-fns";
import { Button } from "@nextui-org/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import { useCustomToolbar } from "./useCustomToolbar";
import { AsIconButton, reactSelectStyles } from "app/subframe";
import { Icon } from "@subframe/core";

export const CustomToolbar: React.FC<any> = ({ date, onNavigate }) => {
  const { onShopsChange, goToBack, goToNext, goToToday, shops, shop } =
    useCustomToolbar({
      onNavigate,
    });

  return (
    <div className="flex items-center relative justify-center">
      <div className="flex items-center !absolute left-4 z-[100]">
        <Select
          key={`shop-select-${shop?.id}`}
          className="w-52 text-sm"
          options={shops.map((shop) => ({
            value: shop.id,
            label: shop.name,
            complete: shop?.latitude && shop?.longitude && shop.addressLine1,
          }))}
          onChange={onShopsChange}
          placeholder="Select Shop"
          defaultValue={{
            value: shop?.id,
            label: shop?.name,
          }}
          styles={reactSelectStyles({
            borderContainer: true,
          })}
          isOptionDisabled={(option: any) => !option.complete}
        />
      </div>
      <div className="flex self-center justify-between my-5 items-center bg-white w-full max-w-[320px] border-gray-300">
        <AsIconButton
          variant="ghost"
          onClick={goToBack}
          size="xs"
          className="text-gray-600"
          leadingIcon="FeatherChevronLeft"
        />
        <span className="font-semibold text-zinc-700">
          {format(date, "EEE, MMM dd")}
        </span>
        <AsIconButton
          variant="ghost"
          onClick={goToNext}
          size="xs"
          className="text-gray-600"
          leadingIcon="FeatherChevronRight"
        />
      </div>
    </div>
  );
};
