import * as React from "react";
import { useNavbar } from "./useNavbar";
import SchedulesSvg from "app/assets/icons/navbar/schedules.svg";
import SalesSvg from "app/assets/icons/navbar/sales.svg";
import ClientsSvg from "app/assets/icons/navbar/clients.svg";
import BusinessSettingsSvg from "app/assets/icons/navbar/businessSettings.svg";
import StaffSvg from "app/assets/icons/navbar/staff.svg";
import ServicesSvg from "app/assets/icons/navbar/services.svg";
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import ExpandChevronSvg from "app/assets/icons/navbar/expandChevron.svg";
import classNames from "classnames";
import { Path } from "app/path";
import { UserRoles } from "app/redux/userSlice";
import { titleize } from "app/utils/string";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { AsBadge, AsButton, MobileNavBar } from "app/subframe";
import { Avatar } from "app/components/avatar/avatar";
import LogoSvg from "app/assets/images/brand/darkLogo.svg";
import AllSetSvg from "app/assets/images/brand/darkAllSet.svg";
import { UpdateAndGuides } from "app/subframe/components/UpdateAndGuides";
import { Icon } from "@subframe/core";

export interface NavbarProps {}

const navbarItemsGroup1 = [
  {
    img: SchedulesSvg,
    title: "Schedules",
    to: Path.HOME,
    iconHoverTitle: "Schedule",
  },
  { img: SalesSvg, title: "Sales", to: Path.SALES, iconHoverTitle: "Sales" },
  {
    img: ClientsSvg,
    title: "Clients",
    to: Path.CLIENTS,
    iconHoverTitle: "Clients",
  },
];
const navbarItemsGroup2 = [
  {
    img: BusinessSettingsSvg,
    title: "Business Settings",
    to: Path.SETTINGS,
    iconHoverTitle: "Settings",
  },
  { img: StaffSvg, title: "Staff", to: Path.STAFF, iconHoverTitle: "Staff" },
  {
    img: ServicesSvg,
    title: "Services",
    to: Path.BUSINESS_SERVICES,
    iconHoverTitle: "Services",
  },
  // { img: ReportsSvg, title: "Reports", to: Path.HOME, iconHoverTitle: "Reports" },
];

export const Navbar: React.FC<NavbarProps> = (props) => {
  const {
    isExpanded,
    navigate,
    setIsExpanded,
    hoveredNavIcon,
    setHoveredNavIcon,
    user,
    logout,
  } = useNavbar();
  const { isBelowSm, isAboveSm } = useBreakpoint("sm");

  if (isBelowSm) {
    return (
      <MobileNavBar className="mobile:h-16 mobile:w-full fixed bottom-0 z-50" />
    );
  }

  return (
    <div
      className={classNames(
        "flex flex-col h-screen bg-millet-500 px-2 transform duration-300 fixed z-[1000]",
        {
          "w-[56px]": !isExpanded,
          "w-[240px] shadow-md": isExpanded,
        }
      )}
      onMouseOut={() => setHoveredNavIcon(null)}
    >
      <div className="flex relative items-center justify-start ml-2 mt-4 mb-[10px] w-full">
        <img src={LogoSvg} alt="logo" className="w-6 h-7" />
        <img
          className={classNames(
            "font-semibold absolute duration-300 left-9 w-14",
            {
              "opacity-0": !isExpanded,
              "opacity-100": isExpanded,
            }
          )}
          src={AllSetSvg}
        />
        <button
          className={classNames(
            "absolute !p-0 !m-0 w-6 h-6 left-6 transform duration-300 z-10",
            {
              "translate-x-[186px]": isExpanded,
              // "left-9": !isExpanded,
            }
          )}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <img
            src={ExpandChevronSvg}
            alt="expand toggle"
            className={classNames("transform", {
              "rotate-180 right-0": isExpanded,
            })}
          />
        </button>
      </div>
      <div className="flex flex-col gap-3 overflow-visible">
        {navbarItemsGroup1.map((item, index) => {
          const isSelected = window.location.pathname === item.to;
          if (user.role !== UserRoles.ADMIN && item.title !== "Schedules") {
            return null;
          }

          return (
            <div className="relative" key={index}>
              <div className="overflow-hidden w-full">
                <Button
                  variant="light"
                  key={index}
                  className={classNames(
                    "flex items-center justify-start gap-[10px] p-2 rounded-sm w-full hover:!bg-millet-550",
                    {
                      "bg-millet-600 font-semibold": isSelected,
                    }
                  )}
                  onMouseOver={() => setHoveredNavIcon(item.title)}
                  onClick={() => navigate(item.to)}
                >
                  <img src={item.img} alt={item.title} />
                  <p
                    className={classNames(
                      "text-neutral-900 text-sm cursor-pointer",
                      {
                        "font-semibold": isSelected,
                      }
                    )}
                  >
                    {item.title}
                  </p>
                </Button>
              </div>
              <div
                className={classNames(
                  "flex absolute top-0 items-center h-full rounded-sm left-11 p-2 transform duration-300 delay-200 bg-millet-550 -z-10",
                  {
                    "opacity-0": isExpanded || hoveredNavIcon !== item.title,
                    "opacity-100": !isExpanded && hoveredNavIcon === item.title,
                  }
                )}
              >
                <p
                  className={classNames("text-sm", {
                    "font-semibold": isSelected,
                  })}
                >
                  {item.iconHoverTitle}
                </p>
              </div>
            </div>
          );
        })}
        {user.role === UserRoles.ADMIN && <Divider />}
        {navbarItemsGroup2.map((item, index) => {
          const isSelected = window.location.pathname === item.to;
          if (user.role !== UserRoles.ADMIN && item.title !== "Schedules") {
            return null;
          }
          return (
            <div className="relative" key={index}>
              <div className="overflow-auto w-full">
                <Button
                  variant="light"
                  key={index}
                  className={classNames(
                    "flex items-center justify-start gap-[10px] p-2 rounded-sm w-full hover:!bg-millet-550",
                    {
                      "bg-millet-600 font-semibold": isSelected,
                    }
                  )}
                  onMouseOver={() => setHoveredNavIcon(item.title)}
                  onClick={() => navigate(item.to)}
                >
                  <img src={item.img} alt={item.title} />
                  <span
                    className={classNames("text-neutral-900 text-sm", {
                      "font-semibold": isSelected,
                    })}
                  >
                    {item.title}
                  </span>
                </Button>
                <div
                  className={classNames(
                    "flex absolute top-0 items-center h-full rounded-sm left-11 p-2 transform duration-300 delay-200 bg-millet-550 -z-10",
                    {
                      "opacity-0": isExpanded || hoveredNavIcon !== item.title,
                      "opacity-100":
                        !isExpanded && hoveredNavIcon === item.title,
                    }
                  )}
                >
                  <p
                    className={classNames("text-sm", {
                      "font-semibold": isSelected,
                    })}
                  >
                    {item.iconHoverTitle}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-4 items-start absolute left-0 bottom-6">
        <UpdateAndGuides
          variant="default"
          collapsed={!isExpanded}
          className="ml-2"
        />
        <Dropdown
          classNames={{
            base: "w-[234px]",
            content: "rounded-sm shadow-lg border border-neutral-50",
          }}
        >
          <DropdownTrigger
            className={
              "ml-2 self-start items-center min-w-[100px] cursor-pointer"
            }
          >
            <div className="flex">
              <Avatar src={user.avatar} name={user.name} size={40} />
              <div
                className={classNames(
                  "flex justify-between items-center w-full pr-2 duration-300 ",
                  {
                    "opacity-0": !isExpanded,
                    "opacity-100": isExpanded,
                  }
                )}
              >
                <p className="px-2 text-sm min-w-[100px]">{user.name}</p>
                <Icon name="FeatherChevronUp" />
              </div>
            </div>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            className="!rounded-sm"
            disabledKeys={["info", "billing"]}
          >
            <DropdownItem key="info" className="!opacity-100" showDivider>
              <div className="flex items-center gap-2 !mb-2">
                <Avatar
                  src={user.avatar}
                  name={user.name}
                  size={40}
                  className="rounded-sm w-10 h-10"
                />
                <div className="flex flex-col">
                  <p className="text-center font-semibold">{user.name}</p>

                  <AsBadge
                    variant="success"
                    icon={null}
                    iconRight={null}
                    className="w-fit"
                  >
                    {titleize(user.role)}
                  </AsBadge>
                </div>
              </div>
            </DropdownItem>
            <DropdownItem
              key="settings"
              onClick={() => navigate(`${Path.USER}?path=details`)}
              className="!p-0 !bg-transparent"
            >
              <AsButton
                leadingIcon={"FeatherCog"}
                variant="ghost"
                className="w-full justify-start items-center"
                text="User setting"
                size="md"
              />
            </DropdownItem>
            <DropdownItem key="billing" className="!p-0 !bg-transparent">
              <div className="flex justify-between items-center">
                <AsButton
                  leadingIcon={"FeatherCreditCard"}
                  variant="ghost"
                  className="justify-start items-center"
                  text="Billing"
                  size="md"
                />
                <p className="text-[10px]">Coming soon</p>
              </div>
            </DropdownItem>
            <DropdownItem
              key="logout"
              onClick={() => logout()}
              className="!p-0 !bg-transparent"
            >
              <AsButton
                trailingIcon={"FeatherLogOut"}
                variant="ghost"
                className="w-full justify-between items-center"
                text="Log out"
                size="md"
              />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
