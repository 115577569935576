import * as React from "react";
import { useAddCategoryModal } from "./useAddCategoryModal";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

export interface AddCategoryModalProps {
  showAddCategoryModal: boolean;
  setShowAddCategoryModal: (show: boolean) => void;
  setOpenCategoryId: (id: number) => void;
}

export const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
  showAddCategoryModal,
  setShowAddCategoryModal,
  setOpenCategoryId,
}) => {
  const { onFormChange, isDirty, onSubmit } = useAddCategoryModal({
    setShowAddCategoryModal,
    setOpenCategoryId,
  });
  return (
    <Modal
      isOpen={showAddCategoryModal}
      onOpenChange={setShowAddCategoryModal}
      size="lg"
      className="rounded"
      disableAnimation
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col items-center gap-10">
              Create new service category
            </ModalHeader>
            <form
              className="flex flex-col"
              onChange={(e) => onFormChange(e)}
              onSubmit={onSubmit}
            >
              <ModalBody className="py-4">
                <Input
                  classNames={{
                    label: "!text-[#71717A]",
                    inputWrapper:
                      "bg-transparent rounded shadow-sm border border-[#F4F4F5]",
                    input: "font-semibold placeholder:font-normal",
                  }}
                  labelPlacement="outside"
                  variant="bordered"
                  className="text-[#71717A]"
                  label="Category name"
                  name="category"
                  placeholder="Hair Services"
                  // defaultValue={shop.name}
                  isRequired
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  className="w-[200px] rounded-sm self-end font-semibold border border-zinc-400"
                  variant="ghost"
                  isDisabled={!isDirty}
                  type="submit"
                >
                  Create
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
