import * as React from "react";
import { useClientDrawer } from "../useClientDrawer";
import {
  Button,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import PhoneInput from "react-phone-input-2";
import { AsButton, AsInput } from "app/subframe";
import { InputEdit } from "app/components/inputEdit/InputEdit";

export interface AddClientProps {
  setCloseParent?: (isOpen: boolean) => void;
}

export const AddClient: React.FC<AddClientProps> = ({ setCloseParent }) => {
  const {
    onSubmit,
    isCreatingClient,
    profileImagePreview,
    handleProfileImageChange,
  } = useClientDrawer({ setCloseParent });

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col gap-6 pb-10 h-[100dvh] overflow-auto"
    >
      <p className="font-semibold text-gray-800">Add Client</p>

      <div className="flex flex-col justify-center rounded pointer bg-zinc-100 items-center border border-dashed w-[100px] h-[100px] gap-4">
        {profileImagePreview ? (
          <img
            src={profileImagePreview}
            alt="Profile Preview"
            className="h-[100px] w-[100px] rounded border"
          />
        ) : (
          <label
            htmlFor="profile-upload"
            className="flex flex-col gap-3 w-[100px] text-center h-[100px] bg-zinc-100 items-center justify-center border-dashed border-2 p-[10px] rounded cursor-pointer"
          >
            <PlusIcon className="h-5 w-5 stroke-black" />
            <p className=" text-sm">Upload Profile</p>
          </label>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleProfileImageChange}
          className="hidden"
          id="profile-upload"
          name="profileImage"
        />
      </div>

      <AsInput
        name="firstName"
        placeholder="Enter first name"
        textPosition="left"
        label="First name"
        required
      />

      <AsInput
        name="lastName"
        placeholder="Enter last name"
        textPosition="left"
        label="Last name"
        required
      />

      <AsInput
        name="email"
        placeholder="Enter email"
        textPosition="left"
        label="Email"
        required
      />

      <div className="flex flex-col gap-2">
        <label className="text-body-bold font-body-bold text-sm text-neutral-700">
          Phone
        </label>
        <PhoneInput
          inputProps={{ name: "phone" }}
          specialLabel=""
          placeholder="+1 (555) 555-5555"
          inputClass="custom-input pxy-1 !text-sm !shadow-none placeholder:font-semibold hover:!border-neutral-200"
        />
      </div>
      <div className="flex flex-col gap-2">
        <RadioGroup
          label="Gender"
          name="gender"
          orientation="horizontal"
          defaultValue={"non_binary"}
          validationBehavior="native"
          classNames={{
            wrapper: "flex justify-between w-full pr-4",
            label: "!text-zinc-700 text-sm font-semibold",
          }}
        >
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-sm ml-4",
            }}
            value="female"
          >
            Female
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-sm ml-4",
            }}
            value="male"
          >
            Male
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-sm ml-4",
            }}
            value="non_binary"
          >
            Non-Binary
          </Radio>
        </RadioGroup>
      </div>
      <Textarea
        variant="bordered"
        label="Notes"
        labelPlacement="outside"
        disableAnimation
        disableAutosize
        placeholder="Enter client notes"
        name="note"
        classNames={{
          label: "!text-zinc-700 font-semibold text-sm",
          inputWrapper:
            "bg-transparent rounded shadow-sm border border-[#F4F4F5]",
          input:
            "font-semibold resize-y min-h-[120px] placeholder:font-normal mobile:text-medium",
        }}
      />
      <AsButton
        size="md"
        type="submit"
        disabled={isCreatingClient}
        text="Add"
        loader={isCreatingClient}
      />
    </form>
  );
};
