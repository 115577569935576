import { Path } from "app/path";
import { OnboardingStep, selectOnboarding } from "app/redux/onboardingSlice";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const useOnboarding = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const onboarding = useSelector(selectOnboarding);
  const [currentStep, setCurrentStep] = useState(step);

  useEffect(() => {
    if (onboarding.step === OnboardingStep.COMPLETE || !step) {
      return navigate(`${Path.SETTINGS}?path=location`);
    }
  }, []);

  return { currentStep, setCurrentStep, navigate };
};
