import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "@nextui-org/react";
import * as React from "react";
import { useInviteModal } from "./useInviteModal";
import Select from "react-select";
import { reactSelectStyles } from "app/subframe";

export interface InviteModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export const InviteModal: React.FC<InviteModalProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const { onSubmit, isLoading } = useInviteModal({ onOpenChange });
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="xl"
      className="rounded p-4"
      disableAnimation
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col items-center gap-10">
              Invite a new staff member
            </ModalHeader>
            <form className="flex flex-col" onSubmit={onSubmit}>
              <ModalBody className="py-4">
                <Input
                  classNames={{
                    inputWrapper: "custom-input",
                  }}
                  labelPlacement="outside"
                  variant="bordered"
                  label="Name"
                  name="name"
                  placeholder="Enter name"
                  validationBehavior="native"
                  isRequired
                />
                <Input
                  classNames={{
                    inputWrapper: "custom-input",
                  }}
                  labelPlacement="outside"
                  variant="bordered"
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  validationBehavior="native"
                  isRequired
                />
                <div className="flex flex-col gap-2">
                  <label className="text-zinc-800 text-sm">
                    Role of new user <span className="text-red-500">*</span>
                  </label>
                  <Select
                    options={[
                      { value: "admin", label: "Admin" },
                      { value: "employee", label: "Employee" },
                    ]}
                    className="text-sm rounded-sm bg-zinc-400"
                    name="role"
                    placeholder="Select role"
                    styles={reactSelectStyles({})}
                    required
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="w-[200px] bg-black text-white rounded-sm self-end font-semibold border border-zinc-400"
                  type="submit"
                  isDisabled={isLoading}
                >
                  {isLoading && <Spinner size="sm" color="default" />}
                  Invite
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
