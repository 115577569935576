import * as React from "react";
import { useBusinessInfo } from "./useBusinessInfo";
import { AsButton } from "app/subframe";
import { BrandInput } from "app/subframe/components/BrandInput";
import { LogoWithName } from "app/components/logoWithName";

export interface BusinessInfoProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

export const BusinessInfo: React.FC<BusinessInfoProps> = ({
  step,
  totalSteps,
  setCurrentStep,
}) => {
  const { onSubmit, loading, account, user } = useBusinessInfo({
    setCurrentStep,
  });

  return (
    <div className="flex h-full w-full flex-col items-center gap-16 bg-millet-500 px-10 py-10 pt-20 mobile:flex-col mobile:gap-14 mobile:px-4 mobile:pt-10 mobile:pb-20">
      <div className="absolute top-5 left-5">
        <LogoWithName width="4" height="4" />
      </div>
      <form
        onSubmit={onSubmit}
        className="flex flex-col items-center gap-14 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0"
      >
        <div className="flex flex-col items-center justify-center gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
          <span className="text-[14px] font-[400] leading-[20px] text-neutral-700">
            Step {step} of {totalSteps}
          </span>
          <span className="text-heading-2 font-heading-2 text-neutral-700 text-center">
            What&#39;s the name of your business
          </span>
        </div>
        <div className="flex flex-col items-center justify-center gap-12 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-start">
          <div className="flex flex-col items-center justify-center gap-10 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-start">
            <div className="flex w-96 flex-col items-start gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                Business name *
              </span>
              <BrandInput
                className="h-14 w-full flex-none"
                placeholder="Enter your business name *"
                textPosition="left"
                required
                name="businessName"
                defaultValue={
                  user?.email === account?.name ? "" : account?.name
                }
              />
            </div>
            <div className="flex w-96 flex-col items-start gap-2 mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                Website
              </span>
              <BrandInput
                className="h-14 w-full flex-none"
                placeholder="Your business website"
                textPosition="left"
                name="website"
                defaultValue={account?.website}
              />
            </div>
            {/* <div className="flex w-96 flex-col items-start gap-2 rounded-sm mobile:h-auto mobile:w-full mobile:flex-none">
              <span className="text-body-bold font-body-bold text-neutral-700">
                Reseller
              </span>
              <Select
                options={
                  resellers.map((reseller: any) => ({
                    value: reseller.id,
                    label: reseller.name,
                  })) || []
                }
                className="text-sm w-full mobile:text-medium"
                placeholder="Select reseller"
                name="resellerId"
                defaultValue={
                  account?.reseller?.name
                    ? {
                        value: account?.resellerId,
                        label: account?.reseller?.name,
                      }
                    : undefined
                }
                isClearable
              />
            </div> */}
          </div>
          <AsButton
            className="h-12 w-full flex-none"
            variant="brand"
            leadingIcon={null}
            trailingIcon={null}
            text="Continue"
            size="md"
            loader={loading}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
