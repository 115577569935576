import * as React from "react";
import { UserSettingTypes, useUser } from "./useUser";
import { Listbox, ListboxItem } from "@nextui-org/react";
import classNames from "classnames";
import path from "path";
import { UserDetails } from "./components/userDetails/userDetails";
import { Security } from "./components/security/security";

export interface UserProps {}

export const User: React.FC<UserProps> = ({}) => {
  const { user, path, onMenuPress } = useUser();

  const view = () => {
    switch (path) {
      case UserSettingTypes.DETAILS:
        return <UserDetails />;
      case UserSettingTypes.SECURITY:
        return <Security />;
      default:
        return <div>Details</div>;
    }
  };

  return (
    <div className="flex gap-6 my-10 max-w-[750px] mx-auto">
      <div className="flex flex-col w-full max-w-[200px]">
        <Listbox
          // className="flex flex-col p-2 justify-start items-start rounded shadow-dialog-shadow bg-white"
          className="p-2 justify-start items-start w-[200px] rounded shadow-dialog-shadow fixed top-10 z-10 bg-white"
          aria-label="Actions"
          onAction={(key: any) => onMenuPress(key)}
          classNames={{
            list: "gap-2",
          }}
        >
          <ListboxItem
            classNames={{
              base: `${
                path === UserSettingTypes.DETAILS ? "bg-millet-500" : ""
              } rounded-sm !p-2 hover:!bg-millet-400`,
            }}
            key={UserSettingTypes.DETAILS}
          >
            <p
              className={classNames(
                path === UserSettingTypes.DETAILS ? "font-semibold" : ""
              )}
            >
              User details
            </p>
          </ListboxItem>
          <ListboxItem
            classNames={{
              base: `${
                path === UserSettingTypes.SECURITY ? "bg-millet-500" : ""
              } rounded-sm !p-2 hover:!bg-millet-400`,
            }}
            key={UserSettingTypes.SECURITY}
          >
            <p
              className={classNames(
                path === UserSettingTypes.SECURITY ? "font-semibold" : ""
              )}
            >
              Security
            </p>
          </ListboxItem>
        </Listbox>
      </div>
      <div className="h-[90vh] overflow-auto w-full">{view()}</div>
    </div>
  );
};
