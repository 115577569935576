import * as React from "react";
import EmptyStatePng from "app/assets/images/emptyState.png";

export interface EmptyStateProps {}

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  return (
    <div className="max-w-[300px]">
      <img src={EmptyStatePng} alt="Empty State" />
    </div>
  );
};
