import { Button, Divider, Input } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useSecurity } from "./useSecurity";
import { AsButton, AsInput } from "app/subframe";

export interface SecurityProps {}

export const Security: React.FC<SecurityProps> = (props) => {
  const { onFormChange, allFieldsFilled, onSubmit, isLoading } = useSecurity();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Security" />
      <form
        className="p-4 flex flex-col rounded border border-neutral-50"
        onChange={onFormChange}
        onSubmit={onSubmit}
      >
        <p className="text-body-bold font-body-bold text-neutral-900">
          {" "}
          Update password{" "}
        </p>
        <Divider className="my-4" />
        <div className="flex flex-col gap-10 mb-10">
          <AsInput
            placeholder="Enter your current password"
            name="currentPassword"
            textPosition="left"
            required
          />
          <AsInput
            placeholder="Enter your new password"
            name="newPassword"
            textPosition="left"
            required
          />
          <AsInput
            placeholder="Confirm your new password"
            name="confirmPassword"
            textPosition="left"
            required
          />
        </div>
        <AsButton
          className="w-[200px] self-end"
          size="md"
          variant="neutral-secondary"
          loader={isLoading}
          disabled={!allFieldsFilled}
          type="submit"
        >
          Update
        </AsButton>
      </form>
    </div>
  );
};
