import { EmptyState } from "app/components/emptyState";
import { AsButton } from "app/subframe";
import classNames from "classnames";
import { format } from "date-fns";
import * as React from "react";

export interface CustomTimeSlotWrapperProps {}

export const CustomTimeSlotWrapper: React.FC<any> = ({
  children,
  value,
  resource,
}) => {
  let finalChildren = children;

  // Check if the time slot is at the top of the hour (minutes are "00")
  const isHourMark = format(value, "mm") === "00";

  if (isHourMark && !resource) {
    const [hour, ampm] = format(value, "h a").split(" ");
    const formattedAmPm = ampm === "AM" ? "a" : "p";
    finalChildren = `${hour}${formattedAmPm}`;
  } else {
    finalChildren = null; // Hide non-hour time slots
  }

  // Return the time slot wrapper with the custom style and children elements
  return (
    <div
      className={classNames(
        "flex h-5 items-center justify-center px-[10px] border-none text-zinc-500 text-sm leading-tight",
        {
          "translate-y-14": finalChildren,
        }
      )}
    >
      {finalChildren}
    </div>
  );
};
