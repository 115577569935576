import * as React from "react";
import { useClosedSaleScreen } from "./useClosedSaleScreen";
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import { format } from "date-fns";
import { titleize } from "app/utils/string";
import {
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import {
  AsBadge,
  AsButton,
  AsIconButton,
  CardInfo,
  LineItems,
} from "app/subframe";
import { PaymentMethod } from "app/redux/paymentTransactionSlice";
import { SaleStatusTypes } from "app/redux/salesSlice";
import { Icon } from "@subframe/core";
import { Avatar } from "app/components/avatar/avatar";

export interface ClosedSaleScreenProps {
  setShouldShowDrawer: (value: boolean) => void;
}

export const ClosedSaleScreen: React.FC<ClosedSaleScreenProps> = ({
  setShouldShowDrawer,
}) => {
  const { sale, loading, onVoidSale, loadingReason, onCloneSale, onOpenSale } =
    useClosedSaleScreen();

  if (loading || !sale.client) {
    return (
      <Spinner
        label={loadingReason}
        size="lg"
        className="absolute left-1/2 top-1/3 -translate-x-1/2"
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center gap-3 mt-3 mb-6 justify-between">
        <div className="flex gap-3">
          <p className="text-body-bold font-body-bold text-neutral-900">
            Sale #{sale.id}
          </p>
          <AsBadge
            variant={sale.status === SaleStatusTypes.VOID ? "void" : "neutral"}
            icon={null}
            iconRight={null}
          >
            {titleize(sale.status)}
          </AsBadge>
        </div>
        <div className="flex items-center gap-4">
          {sale.status !== SaleStatusTypes.VOID && (
            <Dropdown
              classNames={{
                content: "rounded-sm",
              }}
            >
              <DropdownTrigger>
                <AsIconButton
                  variant="ghost"
                  size="sm"
                  leadingIcon={"FeatherMoreHorizontal"}
                />
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  variant="flat"
                  key={SaleStatusTypes.VOID}
                  className="rounded-sm"
                  color="default"
                  onClick={onVoidSale}
                >
                  Void
                </DropdownItem>
                <DropdownItem
                  variant="flat"
                  key={SaleStatusTypes.OPEN}
                  className="rounded-sm"
                  color="default"
                  onClick={onOpenSale}
                >
                  Reopen
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <AsIconButton
            variant="ghost"
            className="rounded p-1 w-6 h-6"
            onClick={() => setShouldShowDrawer(false)}
          >
            <XMarkIcon />
          </AsIconButton>
        </div>
      </div>
      {sale.status === SaleStatusTypes.VOID && (
        <div className="flex flex-col p-4 gap-1 border border-neutral-50 bg-[#FAFAFA] rounded">
          <div className="flex items-center">
            <ExclamationTriangleIcon className="w-5 h-5 stroke-[#F5A524] mr-1" />
            <p className="font-semibold text-sm">
              You've voided this transaction
            </p>
          </div>
          <p className="text-sm mb-4">
            This transaction is cancelled, and the payment will not be <br />{" "}
            processed. If you want to create a new payment for this sale, <br />{" "}
            press the{" "}
            <span className="text-body-bold font-body-bold text-neutral-900">
              “Clone this sale”
            </span>{" "}
            button below.
          </p>
          <Button
            variant="bordered"
            className="bg-white  rounded-sm w-full border-black font-semibold p-[10px]"
            onClick={() => onCloneSale(sale)}
          >
            Clone this sale
          </Button>
        </div>
      )}
      <div className="flex items-center gap-5">
        <Avatar
          name={sale.client?.name}
          src={sale.client?.avatar}
          type="character"
          size={56}
        />
        <div className="flex flex-col gap-2 my-6">
          <p className="text-body-bold font-body-bold text-neutral-900">
            {sale.client?.name}
          </p>
          <p className="text-zinc-500 text-sm">
            Client since {format(sale.client?.createdAt, "M.dd.yyyy")}
          </p>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-6 pt-6">
        {sale.salesMerchantServices.map((service: any) => (
          <div className="flex items-center justify-between" key={service.id}>
            <div className="flex flex-col gap-2" key={service.id}>
              <p className="text-body-bold font-body-bold text-neutral-900">
                {service.title}
              </p>
              <div className="flex">
                <div className="flex gap-1 text-sm">
                  <span className="text-zinc-500 text-sm">with</span>
                  <div className="font-semibold text-zinc-900 cursor-pointer relative hover:underline">
                    {service?.employee?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-body-bold font-body-bold text-neutral-900">
                {service.price}
              </p>
            </div>
          </div>
        ))}
        <Divider />
      </div>
      <div className="flex flex-col py-6 gap-6">
        <LineItems title="Subtotal" price={sale.subtotal} />
        <LineItems title="Tax" price={sale.paidTax} />
        <LineItems title="Tip" price={sale.paidTips} />
        <LineItems title="Total" price={sale.total} />
        <Divider />
        <div className="flex w-full items-center gap-4">
          <div className="flex grow shrink-0 basis-0 items-center gap-4">
            <span className="text-body font-body text-neutral-700">
              Paid with
            </span>
            {sale.paymentTransactions
              .reduce((acc: any[], transaction: any) => {
                const isCreditCard =
                  transaction.paymentMethod !== PaymentMethod.CASH;

                // If it's a credit card and we already have one in the accumulator
                if (isCreditCard) {
                  // Check if there is already a credit card transaction in acc
                  const existingCreditCardIndex = acc.findIndex(
                    (item) => item.isCreditCard
                  );

                  // If there's already a credit card transaction, prioritize the one with last4 or cardType
                  if (existingCreditCardIndex !== -1) {
                    // If the current transaction has more specific info (last4 or cardType), replace the existing one
                    if (transaction.last4 || transaction.cardType) {
                      acc[existingCreditCardIndex] = {
                        last4: transaction.last4,
                        cardType: transaction.cardType,
                        isCreditCard: true,
                        element: (
                          <CardInfo
                            image={transaction.cardTypeImage}
                            text={transaction.last4}
                          />
                        ),
                      };
                    }
                    // Skip adding this transaction if it's not more specific
                    return acc;
                  } else {
                    // If no credit card transaction exists in acc, add the current one
                    let element;
                    if (transaction.cardType || transaction.last4) {
                      element = (
                        <CardInfo
                          image={transaction.cardTypeImage}
                          text={transaction.last4}
                        />
                      );
                    } else {
                      element = (
                        <div className="flex items-center gap-1">
                          <Icon name="FeatherCreditCard" />
                          <p className="text-body font-body text-neutral-700">
                            Card
                          </p>
                        </div>
                      );
                    }

                    acc.push({
                      last4: transaction.last4,
                      cardType: transaction.cardType,
                      isCreditCard: true,
                      element,
                    });
                    return acc;
                  }
                }

                // If it's cash, add it directly
                if (transaction.paymentMethod === PaymentMethod.CASH) {
                  acc.push({
                    last4: transaction.last4,
                    isCreditCard: false,
                    element: (
                      <div className="flex items-center gap-1">
                        <Icon name="FeatherBanknote" />
                        <p className="text-body font-body text-neutral-700">
                          Cash
                        </p>
                      </div>
                    ),
                  });
                }

                return acc;
              }, [])
              .map((item: any) => item.element)}
          </div>

          <span className="text-[16px] font-[600] leading-[24px] text-neutral-900">
            <p className="text-body-bold font-body-bold text-neutral-900">
              {sale.total}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};
