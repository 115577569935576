import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailabilitiesWithOverrideByEmployeeId,
  selectAvailabilities,
} from "app/redux/availabilitiesSlice";
import { RootState } from "app/redux/store";
import { EmployeesSliceState } from "app/redux/employeesSlice";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  parseISO,
  isValid,
} from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { format24TimeTo12Hour } from "app/utils/formatTime";

export const useFormattedAvailabilities = (availabilities: any) => {
  return availabilities.map((availability: any) => {
    const date = parseISO(availability.date);
    const formattedDate = isValid(date) ? format(date, "M/d") : "Invalid date";

    return {
      ...availability,
      formattedDate,
      formattedTimeStart: format24TimeTo12Hour(availability.timeStart),
      formattedTimeEnd: format24TimeTo12Hour(availability.timeEnd),
    };
  });
};

export const useStaffHours = ({
  selectedEmployee,
}: {
  selectedEmployee: EmployeesSliceState;
}) => {
  const dispatch = useDispatch();

  const employeeData = useSelector(
    (state: RootState) => selectAvailabilities(state)[selectedEmployee.id]
  );
  const fetchedDates = employeeData?.fetchedDates || [];
  const [weekDate, setWeekDate] = useState(new Date());
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isAvailabilitiesModalOpen, setIsAvailabilitiesModalOpen] =
    useState<boolean>(false);
  const [selectedAvailability, setSelectedAvailability] = useState<
    any | null
  >();
  const onOpenAvailabilitiesModalClick = (availability: any) => {
    setIsAvailabilitiesModalOpen(true);
    setSelectedAvailability(availability);
  };

  const fetchAvailabilities = async (date = weekDate) => {
    const startOfWeekDate = startOfWeek(date).toISOString();
    if (fetchedDates.includes(startOfWeekDate)) {
      // Filter availabilities from the fetched data
      const allAvailabilities = employeeData?.availabilities || [];
      const weekDates = eachDayOfInterval({
        start: startOfWeek(date),
        end: endOfWeek(date),
      }).map((d) => format(d, "yyyy-MM-dd"));

      const filteredAvailabilities = allAvailabilities.filter((avail) => {
        const availDate = format(parseISO(avail.date), "yyyy-MM-dd");
        return weekDates.includes(availDate);
      });

      setAvailabilities(filteredAvailabilities);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const result = await dispatch(
        getAvailabilitiesWithOverrideByEmployeeId({
          employeeId: selectedEmployee.id,
          date: new Date(startOfWeekDate),
        }) as any
      ).unwrap();
      setAvailabilities(result.availabilities || []);
      setIsLoading(false);
    } catch (err) {
      setError(err as Error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedEmployee?.id) {
      fetchAvailabilities();
    }
  }, [selectedEmployee, weekDate, employeeData?.availabilities]);

  return {
    availabilities,
    isLoading,
    error,
    fetchAvailabilities,
    setWeekDate,
    isAvailabilitiesModalOpen,
    setIsAvailabilitiesModalOpen,
    onOpenAvailabilitiesModalClick,
    selectedAvailability,
  };
};
