"use client";
/*
 * Documentation:
 * AsBadge — https://app.subframe.com/feb895689ba0/library?component=AsBadge_a23f3ea7-3649-4c1b-9f5a-e2094098f065
 */

import React from "react";
import * as SubframeCore from "@subframe/core";

interface AsBadgeRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "brand" | "neutral" | "error" | "warning" | "success" | "void";
  icon?: SubframeCore.IconName;
  children?: React.ReactNode;
  iconRight?: SubframeCore.IconName;
  className?: string;
}

const AsBadgeRoot = React.forwardRef<HTMLElement, AsBadgeRootProps>(
  function AsBadgeRoot(
    {
      variant = "brand",
      icon = null,
      children,
      iconRight = null,
      className,
      ...otherProps
    }: AsBadgeRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/a23f3ea7 flex h-6 items-center gap-1 rounded-sm border border-solid border-neutral-900 bg-neutral-900 px-1",
          {
            "border-none bg-purple-50": variant === "void",
            "border border-solid border-success-100 bg-success-100 px-1 py-0":
              variant === "success",
            "border-none bg-warning-50": variant === "warning",
            "border border-solid border-error-100 bg-error-100":
              variant === "error",
            "border border-solid border-neutral-100 bg-neutral-100":
              variant === "neutral",
          },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-caption font-caption text-brand-700",
            {
              "text-success-800": variant === "success",
              "text-warning-800": variant === "warning",
              "text-error-700": variant === "error",
              "text-neutral-700": variant === "neutral",
            }
          )}
          name={icon}
        />
        {children ? (
          <span
            className={SubframeCore.twClassNames(
              "whitespace-nowrap text-caption-bold font-caption-bold text-white",
              {
                "text-purple-700": variant === "void",
                "text-caption-bold font-caption-bold text-success-800":
                  variant === "success",
                "text-warning-700": variant === "warning",
                "text-error-800": variant === "error",
                "text-neutral-700": variant === "neutral",
              }
            )}
          >
            {children}
          </span>
        ) : null}
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            "text-caption font-caption text-brand-700",
            {
              "text-success-800": variant === "success",
              "text-warning-800": variant === "warning",
              "text-error-700": variant === "error",
              "text-neutral-700": variant === "neutral",
            }
          )}
          name={iconRight}
        />
      </div>
    );
  }
);

export const AsBadge = AsBadgeRoot;
