import { Button, Input, Snippet } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useBusinessDetails } from "./useBusinessDetails";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { AsIconButton } from "app/subframe";
import classNames from "classnames";

export interface BusinessDetailsProps {}

export const BusinessDetails: React.FC<BusinessDetailsProps> = (props) => {
  const { account, onUpdateAccount, onCopyBookingUrl, showCopiedTooltip } =
    useBusinessDetails();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Business Details" />
      <div className="p-4 gap-10 flex flex-col rounded border border-neutral-50">
        <InputEdit
          defaultValue={account?.name}
          label="Business name"
          name="name"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <InputEdit
          defaultValue={account?.description}
          label="Business description"
          name="description"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <InputEdit
          defaultValue={account?.website}
          label="Website"
          name="website"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
        />
        <div className="flex flex-col">
          <p className="text-sm text-[#71717A]">Booking URL</p>
          <div className="flex relative items-center justify-between">
            <Snippet
              classNames={{
                base: "relative w-full bg-transparent px-0 py-0 font-inter",
                pre: "font-inter font-semibold text-medium text-neutral-900",
              }}
              hideCopyButton
              hideSymbol
            >
              {`https://book.heyallset.com${account?.bookingLink}`}
            </Snippet>
            <div className="relative">
              <p
                className={classNames(
                  " duration-300 text-xs text-neutral-900 absolute bg-white p-1 -top-7 -left-7",
                  {
                    "opacity-0": !showCopiedTooltip,
                    "opacity-100": showCopiedTooltip,
                  }
                )}
              >
                Copied!
              </p>
              <AsIconButton
                variant="ghost"
                leadingIcon="FeatherCopy"
                size="xs"
                // className="absolute right-0"
                onClick={() => onCopyBookingUrl()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
