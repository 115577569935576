import * as React from "react";
import { useApiKeys } from "./useApiKeys";
import { SectionHeader } from "app/components/sectionHeader";
import { InputEdit } from "app/components/inputEdit/InputEdit";

export interface ApiKeysProps {}

export const ApiKeys: React.FC<ApiKeysProps> = () => {
  const { account, onUpdateAccount } = useApiKeys();
  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="API Keys" />
      <div className="p-4 gap-10 flex flex-col rounded border border-neutral-50">
        <InputEdit
          defaultValue={account?.acceptBlueApiKey}
          label="Accept Blue API Key"
          name="acceptBlueApiKey"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
          valueShownWhenNotEditing={account?.acceptBlueApiKey?.replace(
            /./g,
            "*"
          )}
        />
        <InputEdit
          defaultValue={account?.acceptBlueApiPin}
          label="Accept Blue API Key Pin"
          name="acceptBlueApiPin"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
          valueShownWhenNotEditing={account?.acceptBlueApiPin?.replace(
            /./g,
            "*"
          )}
        />
        <InputEdit
          defaultValue={account?.acceptBlueTokenizationApi}
          label="Accept Blue Tokenization API Key"
          name="acceptBlueTokenizationApi"
          onSave={(value) => onUpdateAccount({ ...value })}
          valueClassName="text-body-bold font-body-bold text-neutral-900"
          valueShownWhenNotEditing={account?.acceptBlueTokenizationApi.replace(
            /./g,
            "*"
          )}
        />
      </div>
    </div>
  );
};
