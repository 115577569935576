import { Divider } from "@nextui-org/react";
import * as React from "react";
import { useStaffDetails } from "./useStaffDetails";
import { SectionHeader } from "app/components/sectionHeader";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import Select from "react-select";
import { EmployeesSliceState } from "app/redux/employeesSlice";
import { titleize } from "app/utils/string";
import { CameraIcon } from "@heroicons/react/24/solid";
import { Avatar } from "app/components/avatar/avatar";
import { format } from "date-fns";
import { reactSelectStyles } from "app/subframe";

export interface StaffDetailsProps {
  selectedEmployee: EmployeesSliceState;
}

export const StaffDetails: React.FC<StaffDetailsProps> = ({
  selectedEmployee,
}) => {
  const {
    onEmployeeUpdate,
    handleShopSelectionChange,
    existingShops,
    employeeShops,
    handleAvatarChange,
  } = useStaffDetails({
    selectedEmployee,
  });

  const disabled = selectedEmployee?.invitePending;

  return (
    <div className="flex flex-col gap-6">
      <SectionHeader title="Staff Details" />
      <div className="flex flex-col p-4 border rounded border-neutral-50">
        <div className="flex items-center gap-4">
          <label className="relative cursor-pointer">
            <Avatar
              name={selectedEmployee?.name}
              src={selectedEmployee?.avatar}
              size={56}
            />
            <input
              type="file"
              className="hidden"
              onChange={handleAvatarChange} // Call this function when a file is selected
              accept="image/*"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 rounded">
              <CameraIcon className="h-6 w-6 text-white" />
            </div>
          </label>
          <div className="w-full">
            <InputEdit
              defaultValue={selectedEmployee?.name}
              onSave={onEmployeeUpdate}
              name="name"
            />
            <p className="text-sm text-gray-500">
              Member since {format(selectedEmployee.createdAt, "M.dd.yyyy")}
            </p>
          </div>
        </div>
        <Divider className="my-4" />
        <div className="flex flex-col gap-10">
          <InputEdit
            valueClassName="text-zinc-700 text-base font-semibold leading-normal"
            defaultValue={selectedEmployee?.email}
            label="Contact email"
            name="email"
            type="email"
            onSave={onEmployeeUpdate}
          />
          <InputEdit
            valueClassName="text-zinc-700 text-base font-semibold leading-normal"
            defaultValue={selectedEmployee?.phone}
            label="Contact phone"
            name="phone"
            type="phone"
            onSave={onEmployeeUpdate}
          />
          <div className="flex flex-col gap-2">
            <p className="text-zinc-500 text-sm font-normal leading-tight">
              Operating locations
            </p>
            <Select
              key={`select-${selectedEmployee.id}-${employeeShops.length}`} // Use key to force re-render
              defaultValue={employeeShops || []}
              isMulti
              name="colors"
              onChange={handleShopSelectionChange}
              options={existingShops.map((shop) => ({
                value: shop.id,
                label: shop.name,
              }))}
              className="text-sm"
              classNamePrefix="select"
              isDisabled={disabled}
              styles={reactSelectStyles({})}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-zinc-500 text-sm font-normal leading-tight">
              Role
            </p>
            <Select
              key={`select-${selectedEmployee.id}-${selectedEmployee.role}`} // Use key to force re-render
              defaultValue={
                {
                  value: selectedEmployee.role,
                  label: titleize(selectedEmployee.role),
                } as any
              }
              className="text-sm"
              onChange={({ value }) => onEmployeeUpdate({ role: value })}
              options={[
                { value: "admin", label: "Admin" },
                { value: "employee", label: "Employee" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
