import { Divider } from "@nextui-org/react";
import { SectionHeader } from "app/components/sectionHeader";
import { Switch } from "@nextui-org/switch";
import * as React from "react";
import { useBusinessPolicies } from "./useBusinessPolicies";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { InputEdit } from "app/components/inputEdit/InputEdit";

export interface BusinessPoliciesProps {}

export const BusinessPolicies: React.FC<BusinessPoliciesProps> = (props) => {
  const { account, onUpdateAccount } = useBusinessPolicies();

  return (
    <div className="flex flex-col w-full gap-6">
      <SectionHeader title="Business Policies" />
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Booking policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">
                Require a credit card for booking
              </p>
              <p className="text-sm text-neutral-500">
                Request a credit card at booking, even if the customer chooses
                to pay in-store.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.requireCreditCardForBooking}
              onChange={(event) =>
                onUpdateAccount({
                  requireCreditCardForBooking: event.target.checked,
                  enableCashPaymentForBooking: !event.target.checked
                    ? false
                    : account.enableCashPaymentForBooking,
                })
              }
            />
          </div>
          {account.requireCreditCardForBooking && (
            <FadeInSlide direction="top">
              <div className="flex ml-6 gap-16 items-start">
                <div className="flex flex-col gap-1">
                  <p className="text-neutral-900">Enable cash payment</p>
                  <p className="text-sm text-neutral-500">
                    Allow customers to keep their booking and choose to pay with
                    cash if their credit card can’t be validated.
                  </p>
                </div>
                <Switch
                  color="success"
                  size="sm"
                  defaultSelected={account.enableCashPaymentForBooking}
                  onChange={(event) =>
                    onUpdateAccount({
                      enableCashPaymentForBooking: event.target.checked,
                    })
                  }
                />
              </div>
            </FadeInSlide>
          )}
        </div>
      </div>
      <div className="p-4 flex flex-col rounded border border-neutral-50">
        <p className=" text-zinc-500">Marketing policies</p>
        <Divider className="text-zinc-500 mt-4 mb-6" />
        <div className="flex flex-col gap-6">
          <div className="flex gap-16 items-start">
            <div className="flex flex-col gap-1">
              <p className="text-neutral-900">Request a review</p>
              <p className="text-sm text-neutral-500">
                Send a thank-you email half hour post-appointment to request a
                review, encouraging customers to share their experience on
                Google, Yelp, or the platform of your choice.
              </p>
            </div>
            <Switch
              color="success"
              size="sm"
              defaultSelected={account.requestReview}
              onChange={(event) =>
                onUpdateAccount({
                  requestReview: event.target.checked,
                })
              }
            />
          </div>
          {account.requestReview && (
            <FadeInSlide direction="top">
              <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
                <div className="flex flex-col gap-1 w-full">
                  <InputEdit
                    defaultValue={account.reviewLink || ""}
                    name="reviewLink"
                    label="Your review link"
                    isEditing={account.reviewLink === ""}
                    valueClassName="text-body-bold font-body-bold text-neutral-900"
                    onSave={(value) => onUpdateAccount(value)}
                    truncateLength={48}
                  />
                </div>
                {/* <Switch
                  color="success"
                  size="sm"
                  defaultSelected={account.enableCashPaymentForBooking}
                  onChange={(event) =>
                    onUpdateAccount({
                      enableCashPaymentForBooking: event.target.checked,
                    })
                  }
                /> */}
              </div>
            </FadeInSlide>
          )}
        </div>
      </div>
    </div>
  );
};
