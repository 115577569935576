import { IconButton, reactSelectStyles } from "app/subframe";
import * as React from "react";
import { useHeader } from "./useHeader";
import { Avatar } from "app/components/avatar/avatar";
import Select from "react-select";

export interface HeaderProps {
  selectedEmployee: any;
  setSelectedEmployee: (employee: any) => void;
}

export const Header: React.FC<HeaderProps> = ({
  selectedEmployee,
  setSelectedEmployee,
}) => {
  const {
    employees,
    shops,
    shop,
    onShopChange,
    isEmployeeSelectOpen,
    setIsEmployeeSelectOpen,
    isShopSelectOpen,
    setIsShopSelectOpen,
  } = useHeader({
    selectedEmployee,
    setSelectedEmployee,
  });

  return (
    <div className="flex w-112 items-center gap-4 pt-6 mobile:h-auto mobile:w-full mobile:flex-none mobile:px-2 mobile:pt-6 mobile:pb-0">
      <div className="flex grow shrink-0 basis-0 items-center ">
        <Avatar
          src={selectedEmployee.avatar}
          name={selectedEmployee.name}
          type="character"
          className="rounded w-6 h-8"
        />
        <Select
          key={`employee-select-${selectedEmployee?.id}`}
          // styles={{
          //   control: (styles) => ({
          //     ...styles,
          //     border: "none",
          //     boxShadow: "none",
          //     cursor: "pointer",
          //     zIndex: 50,
          //     // padding: "0",
          //   }),
          //   menu: (styles) => ({
          //     ...styles,
          //     borderColor: "#F1F1F2",
          //     borderRadius: 8,
          //     padding: 8,
          //     gap: 8,
          //   }),
          //   menuList: (styles) => ({
          //     ...styles,
          //     gap: 4,
          //     display: "flex",
          //     flexDirection: "column",
          //   }),
          //   singleValue: (styles) => ({
          //     ...styles,
          //     color: isEmployeeSelectOpen ? "#8E8169" : "#444449",
          //   }),
          //   option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          //     ...styles,
          //     color: "#444449",
          //     fontWeight: isSelected ? 500 : 400,
          //     backgroundColor: isFocused
          //       ? "#F5F3EF !important"
          //       : isSelected
          //       ? "#F5F3EF !important"
          //       : "white !important",
          //     borderRadius: 4,
          //   }),
          //   indicatorSeparator: (styles) => ({
          //     display: "none",
          //   }),
          //   indicatorsContainer: (styles) => ({
          //     ...styles,
          //     padding: "0 !important",
          //     color: "black",
          //     transform: "translateX(-16px) !important",
          //     svg: {
          //       color: "rgb(113, 113, 122)",
          //       scale: "0.8",
          //     },
          //   }),
          // }}
          styles={reactSelectStyles({})}
          className="w-44 z-[100] text-sm !border-0 mobile:text-medium"
          onMenuOpen={() => setIsEmployeeSelectOpen(true)}
          onMenuClose={() => setIsEmployeeSelectOpen(false)}
          options={employees.map((employee) => ({
            value: employee.id,
            label: employee.name,
          }))}
          onChange={({ value }: any) =>
            setSelectedEmployee(
              employees.find((employee) => employee.id === value)
            )
          }
          placeholder="Select Shop"
          defaultValue={{
            value: selectedEmployee?.id,
            label: selectedEmployee?.name,
          }}
          // styles={{
          //   control: (styles, state) => reactSelectControlStyles(styles, state),
          //   option: (styles, state) => reactSelectOptionStyles(styles, state),
          // }}
        />
        {/* <span className="text-body-bold font-body-bold text-neutral-700">
          {selectedEmployee?.name}
        </span> */}
      </div>
      <div className="flex items-center">
        <IconButton
          disabled={false}
          variant="neutral-tertiary"
          size="medium"
          icon="FeatherMapPin"
          loading={false}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {}}
        />
        <Select
          key={`shop-select-${shop?.id}`}
          className=" w-full z-[100] text-sm mobile:text-medium"
          styles={reactSelectStyles({})}
          // styles={{
          //   control: (styles) => ({
          //     ...styles,
          //     border: "none",
          //     boxShadow: "none",
          //     cursor: "pointer",
          //     zIndex: 50,
          //     padding: 0,
          //   }),
          //   menu: (styles) => ({
          //     ...styles,
          //     borderColor: "#F1F1F2",
          //     borderRadius: 8,
          //     padding: 8,
          //     width: "160px",
          //     transform: "translateX(-100px)",
          //   }),
          //   menuList: (styles) => ({
          //     ...styles,
          //     gap: 4,
          //     display: "flex",
          //     flexDirection: "column",
          //   }),
          //   singleValue: (styles) => ({
          //     ...styles,
          //     color: isShopSelectOpen ? "#8E8169" : "#444449",
          //   }),
          //   option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          //     ...styles,
          //     color: "#444449",
          //     fontWeight: isSelected ? 500 : 400,
          //     backgroundColor: isFocused
          //       ? "#F5F3EF !important"
          //       : isSelected
          //       ? "#F5F3EF !important"
          //       : "white !important",
          //     borderRadius: 4,
          //   }),
          //   valueContainer: (styles) => ({
          //     ...styles,
          //     padding: 0,
          //   }),
          //   indicatorsContainer: (styles) => ({
          //     display: "none",
          //   }),
          // }}
          options={shops.map((shop) => ({
            value: shop.id,
            label: shop.name,
            complete: shop?.latitude && shop?.longitude && shop.addressLine1,
          }))}
          onMenuOpen={() => setIsShopSelectOpen(true)}
          onMenuClose={() => setIsShopSelectOpen(false)}
          onChange={onShopChange}
          placeholder="Select Shop"
          defaultValue={{
            value: shop?.id,
            label: shop?.name,
          }}
          isOptionDisabled={(option: any) => !option.complete}
        />
      </div>
    </div>
  );
};
